@tailwind base;
@tailwind components;
@tailwind utilities;

.container-auto{
    @apply container mx-auto px-2;
}
.filter-brightness-half img{
    @apply brightness-50;
  }
.sidebar ul li a{
    @apply capitalize text-2xl font-bold xl:pl-9 px-4 relative text-black w-full after:content-[''] after:absolute after:top-0 after:left-0 after:w-5 after:h-full after:bg-primary after:hidden;
}
.sidebar ul li a.active {
    @apply text-primary xl:after:block;}

.css-1aquho2-MuiTabs-indicator{
    @apply bg-primary !important;
}
.css-1fgcdis-MuiButtonBase-root-MuiTab-root{
    @apply capitalize !important;
}
.css-19midj6 {
    @apply p-0 !important;
}
.css-1aquho2-MuiTabs-indicator {
    @apply h-[4px] !important; 
}
table tbody tr{
    @apply h-[30px] md:h-[50px];
}
table {
    @apply whitespace-nowrap;
}
table th, table td {
    @apply px-2;
}
h2 {
    @apply text-lg lg:text-xl !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}