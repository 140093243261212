.therapistCalendar {
  .rbc-toolbar button {
    @apply bg-primary text-white hover:bg-[#513e85];
  }
  .rbc-calendar {
    @apply h-[600px] lg:h-[800px];
  }
  .rbc-toolbar {
    @apply mb-8;
  }
  .rbc-header {
    @apply bg-primary text-white uppercase py-1;
  }
  .rbc-today {
    @apply bg-[#c1b7da] text-white;
  }
  .rbc-now {
    @apply text-white;
  }
  .subscribe-box {
    
    .subscribe {
      @apply hidden;
      border-radius: 0 0 4px 4px;
    box-shadow: 0px 0px 2px #c6bfbf;
      a {
        @apply flex gap-2 border-b border p-2 text-primary;
      }
    }
  }
  .subscribe-box:hover .subscribe {
    @apply block;
  }
}
