.react-datepicker-wrapper{
    @apply w-full;
}
.react-datepicker__header{
    padding: 0;
}
.react-datepicker__week{
    display: flex;
}
.react-datepicker__day {
    border: 1px solid #000;
    margin: 0;
    padding:5px 0;
    flex: 1;
    border-radius:0px;
}
.react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover{
    @apply bg-primary text-white ;
    border-radius: 0;
}